import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import ListAll from "./components/ListAll";
import ListSingle from "./components/ListSingle";

class App extends Component {
  render() {
    return (
      <Router>
        <Route path="/machines/:machineid" component={ListSingle} />
        <Route path="/list" isExact={true} component={ListAll} />
      </Router>
    );
  }
}
export default App;
