import React from "react";

import "./simpleOverlay.css";

export default function SimpleOverlay({ ...other }) {
  return (
    <div id="simpleOverlay">
      <p>Ihre Nachricht wurde abgesendet!</p>
    </div>
  );
}
