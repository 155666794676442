import React, { useState, useEffect, Fragment as F } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import useForm from "../hooks/useForm";
import SimpleOverlay from "./SimpleOverlay/SimpleOverlay";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";

export default function ListSingle(props) {
  const [machineData, setMachineData] = useState();
  const [displayOverlay, setDisplayOverlay] = useState();

  const sendErrorMail = () => {
    
    let mailSender = "noreply@cho.si";
    let mailSubject = "Neue Meldung zu " + machineData.machines.Name + " " + machineData.machines.Location;
    let mailMessage = "Absender: " + values.sender + "\nStoerung:" + values.ErrorType + "\nFreitext: " + values.ErrorDescription;
    
    let mailData = {};
    mailData.sender = mailSender;
    mailData.message =  mailMessage;
    mailData.subject = mailSubject;  
	  
	  
     fetch("https://www.servicescanner.de/sendmail", {
       method: "POST", // *GET, POST, PUT, DELETE, etc.
       headers: {
         "Content-Type": "application/json;charset=utf-8"
       },
       body: JSON.stringify(mailData) // body data type must match "Content-Type" header
     })
      .then(response => response.json())
      .then(data => {
         setMachineData({ machines: data });
         console.log(machineData.machines);
       })
       .catch(console.log);
	setDisplayOverlay(true);
    //console.log(values);
    //setDisplayOverlay(true);
  };
  const { values, handleChange, handleSubmit } = useForm(sendErrorMail);
  const url =
    "https://dev.cho.si/admin/machines/" +
    props.match.params.machineid +
    ".json";

  useEffect(() => {
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setMachineData({ machines: data });
      })
      .catch(console.log);
  }, [url]);

  if (machineData) {
    return (
      <F key={machineData.machines.ID}>
        {displayOverlay && <SimpleOverlay />}
        <ListGroup>
          {/*<ListGroup.Item className="list-group-item active">{machineData.machines.Type} {machineData.machines.Name} {machineData.machines.Manufacturer}</ListGroup.Item>*/}
          <ListGroup.Item className="list-group-item active h3">
            {machineData.machines.Name}
          </ListGroup.Item>
          <ListGroup.Item className="list-group-item">
            <h4>Willkommen!</h4>
            <p>
              Bitte die Buttons benutzen, um weitere Informationen zu erhalten.
            </p>
          </ListGroup.Item>
        </ListGroup>
        <Accordion allowZeroExpanded="true">
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>Info</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div
                dangerouslySetInnerHTML={{ __html: machineData.machines.Price }}
              />
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>Bedienung</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div
                dangerouslySetInnerHTML={{ __html: machineData.machines.Usage }}
              />
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>Spielanleitung</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {!machineData.machines.Video ? (
                <F />
              ) : (
                <F>
                  <p>Videotutorial</p>
                  <iframe
                    src={machineData.machines.Video}
                    frameBorder="0"
                    width="100%"
                    height="240px"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    title="video"
                  />
                </F>
              )}
              {!machineData.machines.Manual ? (
                <h4>Keine Anleitung</h4>
              ) : (
                <div>
                  <a href={"https://dev.cho.si" + machineData.machines.Manual}>
                    Anleitung
                  </a>
                </div>
              )}
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>St&ouml;rungsmeldung</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Form onSubmit={handleSubmit}>
                <F>
                  <Form.Group controlId="formBasicChecbox">
                    <Form.Control
                      as="select"
                      name="ErrorType"
                      onChange={handleChange}
                    >
	    	      <option>Bitte ausw&auml;hlen...</option>
                      {machineData.machines.Options.map((value, index) => {
                        return <option value={value}>{value}</option>;
                      })}
                    </Form.Control>
                  </Form.Group>
                  {!machineData.machines.NoSupport && (
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label>Fehlerbeschreibung:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="3"
                        name="ErrorDescription"
                        onKeyDown={handleChange}
                      />
                    </Form.Group>
                  )}
                </F>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>E-Mail (optional)</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="E-Mail eingeben"
                    name="sender"
	    	    onChange={handleChange}
                  />
                  <Form.Text>
                    Falls Du Feedback zu deiner St&ouml;rungsmeldung von uns
                    erhalten m&ouml;chtest, gib hier deine E-mail an.
                  </Form.Text>
                </Form.Group>
                <Button variant="secondary" type="submit">
                  Senden
                </Button>
              </Form>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </F>
    );
  }

  return "Wird geladen...";
}
