import React, { useState, useEffect } from "react";
var QRCode = require("qrcode.react");

export default function ListAll({ ...other }) {
  const [machineData, setMachineData] = useState();
  const url = "https://dev.cho.si/admin/machines.json";

  useEffect(() => {
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setMachineData({ machines: data });
      })
      .catch(console.log);
  }, [url]);

  if (machineData) {
    return (
      <div className="container">
        {machineData.machines.map(machine => (
          <div style={{ marginBottom: 100 }} key={machine.ID}>
            <ul className="list-group">
              <li className="list-group-item active">{machine.Name}</li>
              <li className="list-group-item">{machine.Type}</li>
              <li className="list-group-item">{machine.Location}</li>
              <li className="list-group-item">{machine.Notes}</li>
            </ul>
            <br />
            <QRCode value={"https://www.servicescanner.de/machines/" + machine.ID} />
          </div>
        ))}
      </div>
    );
  }
  return "Wird geladen...";
}
